import { CreateControllerFn, ControllerParams } from '@wix/yoshi-flow-editor';
import { createEventHandler, getSettingsValue } from '@wix/tpa-settings';
import {
  ButtonState,
  CategoriesTabsState,
  ChallengesCustomEvents,
  ISettingsEvents,
} from './Widget/settingsEvents';
import {
  arrayToObjectAsync,
  getBaseProvidersData,
} from '../../contexts/main/getBaseProvidersData';
import { ScreenNames } from '../../contexts/main/biInterfaces';
import programListSettings from './settingsParams';
import { renderSeoMetatagsList } from '../../services/SeoMetatagsList';
import { getCategoriesInitialData } from '../../contexts/storage-contexts/Categories';
import { getChallengesListInitialData } from '../../contexts/storage-contexts/ChallengesList';
import { getPaidPlansInitialDataForList } from '../../contexts/storage-contexts/PaidPlans';

const createController: CreateControllerFn = async ({
  controllerConfig,
  flowAPI,
}: ControllerParams) => {
  const { setProps } = flowAPI.controllerConfig;
  const publicData = controllerConfig.config.publicData.COMPONENT || {};

  // settings panel pub/sub https://github.com/wix-private/tpa-settings#events-between-settings-panel-and-applications
  const settingsEventsHandler = createEventHandler<ISettingsEvents>(publicData);
  settingsEventsHandler.on(ChallengesCustomEvents.buttonState, (value) => {
    setProps({
      buttonState: value,
    });
  });

  settingsEventsHandler.on(
    ChallengesCustomEvents.categoriesTabState,
    (categoriesTabsState) => {
      setProps({
        categoriesTabsState,
      });
    },
  );

  // subscribe on reset. When setting panel is closed
  settingsEventsHandler.onReset(() => {
    setProps({
      buttonState: ButtonState.Default,
      categoriesTabsState: CategoriesTabsState.Regular,
    });
  });

  return {
    async pageReady() {
      const initialProps = {
        ...(await arrayToObjectAsync([
          getBaseProvidersData({
            flowAPI,
          }),
          getCategoriesInitialData(flowAPI),
        ])),
        ...(await arrayToObjectAsync([
          getChallengesListInitialData(flowAPI),
          getPaidPlansInitialDataForList(flowAPI),
        ])),
      };

      flowAPI.bi.updateDefaults({
        origin: flowAPI.controllerConfig?.wixCodeApi?.window?.viewMode,
        _uuid: flowAPI.controllerConfig?.platformAPIs?.bi?.ownerId,
        screenName: ScreenNames.ChallengeListWidget,
      });

      const chVisible = getSettingsValue(
        flowAPI.controllerConfig.config.publicData.COMPONENT || {},
        programListSettings?.challengesVisible,
      );

      renderSeoMetatagsList(
        flowAPI,
        initialProps?.challengesListData?.memberChallenges || [],
        chVisible,
      );

      setProps(initialProps);
    },
    updateConfig($w, config) {
      // notify (events should be fired)
      settingsEventsHandler.notify(config.publicData.COMPONENT || {});
    },
  };
};

export default createController;
